import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  appointmentName: any
  constructor() {

  }

  ngOnInit(): void {
    setTimeout(() => {
      if (sessionStorage.getItem("appointmentName")) {
        this.appointmentName = sessionStorage.getItem("appointmentName")
      }
    }, 2000);
  }

  socialMedia(type) {
    if (type === 'instagram') {
      window.open('https://instagram.com/conceptodiagnostics?igshid=YmMyMTA2M2Y=');
    }
    if (type === 'facebook') {
      window.open('https://www.facebook.com/conceptoclininc/');
    }
    if (type === 'twitter') {
      window.open('https://twitter.com/ConceptoTweets/status/1538866372985475073?s=20&t=3cJaciNOTy2FvaFaBxjsjg');
    }
  }

  accreditationSchedule() {
    window.open('https://www.ukas.com/wp-content/uploads/schedule_uploads/971214/22226-Medical-Multiple.pdf')
  }
}
