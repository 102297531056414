import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { AppConstants } from '../app.constants';


@Injectable()
export class CompleteFormsService {
    private personalDetailFormSource = new BehaviorSubject<any>({});
    currentForm = this.personalDetailFormSource.asObservable();
    constructor(private http: HttpClient) { }

    changFormSource(form: string) {
        this.personalDetailFormSource.next(form)
        sessionStorage.setItem('bookingForm', form)
    }

    getAvailabilityDates(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.FORMS.AVAILABILITYDATES}`, input);
    }
    getAvailabilityTimes(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.FORMS.AVAILABILITYTIMES}`, input);
    }
    postNewApoointment(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.FORMS.NEWAPPOINTMENT}`, input);
    }

    getAppointmentTypes(): Observable<any[]> {
        return this.http.get<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.FORMS.APPOINTMENTTYPES}`);
    }
    getAppointmentTypesById(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.FORMS.APPOINTMENTTYPESBYID}`, input);
    }
    getAppointmentForms(): Observable<any[]> {
        return this.http.get<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.FORMS.APPOINTMENTFORMS}`);
    }
    getByID(id): Observable<any[]> {
        return this.http.get<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.PAYMENT.BYID}?id:${id}`);
    }
    getAppointmentFormsById(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.FORMS.APPOINTMENTFORMSBYID}`, input);
    }
    getCalanders(): Observable<any[]> {
        return this.http.get<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.FORMS.CALANDERS}`);
    }

    makepayment(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.PAYMENT.NEWPAYMENT}`, input);
    }

    makeStripePayment(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.PAYMENT.NEWPAYMENTSTRIPE}`, input);
    }
    checkStatus(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${input}`, '');
    }

    addTransaction(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.PAYMENT.ADDTRANSACTION}`, input);
    }

    updateTransaction(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.PAYMENT.UPDATE}`, input);
    }

    updateTransactionAcuityID(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.PAYMENT.UPDATEACUITYID}`, input);
    }

    
    updateTransactionForm(input): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.PAYMENT.UPDATETRANSACTION}`, input);
    }
}