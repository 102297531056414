const server = {
    // endPoint: 'https://forms.conceptoclinicapp.co.uk/',
    // home: 'https://conceptodiagnostics.co.uk/',
    // diagnostics: 'https://conceptodiagnostics.co.uk/',
    // profile: 'https://conceptodiagnostics.co.uk/',
    // storeId: '2206285717',
    // SharedS: 'tlOou0zAI0F70s4u',
    // GA4: true,
    // lims_endpoint: 'https://lims.conceptoclinicapp.co.uk/',
    // nutrition: 'https://conceptodiagnostics.co.uk/',
    // blueorpink: 'https://blueorpink.co.uk/',


    endPoint: 'https://forms.conceptodiagnostics.co.uk/',
    home: 'https://home.conceptoclinicapp.co.uk/',
    diagnostics: 'https://home.conceptoclinicapp.co.uk/',
    profile: 'https://home.conceptoclinicapp.co.uk/',
    storeId: '2220540956',
    SharedS: 'PTv8*>B8dJ',
    GA4: false,
    lims_endpoint: 'https://qa-lims.conceptoclinicapp.co.uk/',
    nutrition: 'https://home.conceptoclinicapp.co.uk/',
    blueorpink: 'https://blueorpink.co.uk/',

    // endPoint: 'http://localhost:8080/',
    // home: 'http://localhost:4200/',
    // diagnostics: 'http://localhost:4200/',
    // profile: 'http://localhost:4200/',
    // storeId: '2220540956',
    // SharedS: 'PTv8*>B8dJ',
    // GA4: false,
    // lims_endpoint: 'https://qa-lims.conceptoclinicapp.co.uk/',
    // nutrition: 'http://localhost:4200/',
    // blueorpink: 'https://blueorpink.co.uk/'


};

export const AppConstants = {
    API_ENDPOINT: server.endPoint,
    LIMS_ENDPOINT: server.lims_endpoint,
    CART_ENDPOINT: server.profile,

    storeId: server.storeId,
    sharedS: server.SharedS,
    home: server.home,
    diagnostics: server.diagnostics,
    nutrition: server.nutrition,
    blueorpink: server.blueorpink,
    GA4: server.GA4,
    KEY_SHEET: 'c12ad155d13c22e8aef95a15decb83ef104624c7',
    API: {
        FORMS: {
            AVAILABILITYDATES: 'forms/availabiltyDates',
            AVAILABILITYTIMES: 'forms/availabiltyTimes',
            NEWAPPOINTMENT: 'forms/newAppointment',
            APPOINTMENTTYPES: 'forms/appointmentTypes',
            APPOINTMENTTYPESBYID: 'forms/appointmentTypesById',
            APPOINTMENTFORMS: 'forms/appointmentForms',
            APPOINTMENTFORMSBYID: 'forms/appointmentFormsById',
            CALANDERS: 'forms/calander',
            ACUITYBYID: 'upload/acuity-by-id',
            NUTRITION_PACKAGE_ADD: 'nutrition-packages',
            GUEST_USER: 'users/guest',
            NUTRITION_PACKAGE_UPDATE: 'nutrition-packages/update',
            NUTRITION_PACKAGE_BY_URL: 'nutrition-packages/byUrl',
            BLOCK_DATE:'forms/blockDates'
        },
        PAYMENT: {
            NEWPAYMENT: 'forms/payment',
            NEWPAYMENTSTRIPE: 'forms/stripeCheckout',
            ADDTRANSACTION: 'payment/add-transaction',
            BYID: 'payment/byID',
            UPDATE: 'payment/oid-update',
            UPDATEACUITYID: 'payment/acuity-update',
            UPDATETRANSACTION: 'payment/transaction-update',
            LAST: 'payment/last',
            VERIFY:'payment/verify-stripeCheckout'
        },
        EMAIL: {
            NUTRITION_CONFIRMATION: 'payment/nutrition-confirmation'
        },
        IMAGE: {
            ADD: 'forms/addimage'
        },
        BLOCKS: {
            ADD: 'blocks'
        },
        COUPON: {
            UPDATE: 'couponcode/update',
            VALIDATE:'couponcode/bycode'
        },
    }
};
