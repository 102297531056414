import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CompleteFormsService } from './complete-forms.service';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-complete-forms',
  templateUrl: './complete-forms.component.html',
  styleUrls: ['./complete-forms.component.scss']
})
export class CompleteFormsComponent implements OnInit {
  appointmentTypes: any;
  selectedForm: any;
  customForm: FormGroup;
  showForm = false;
  custom_fields: any;
  appointmentTypeID: any;
  calanderID: any;
  toaterMsg: Message[];
  appointmentName = ''
  bookingForm = {};
  items: MenuItem[];
  todaydateTime = new Date()
  oid: any;
  timeoptions: any;
  selectedTime: any;
  availableDates: any;
  availabileDatesMonths: any;
  selectedDate: Date;
  constructor(private router: Router, private customFormsService: CompleteFormsService, private messageService: MessageService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    let url = this.router.url.split('/')
    if (url[url.length - 3]) {
      this.calanderID = url[url.length - 3];
    }
    if (url[url.length - 2]) {
      this.appointmentTypeID = url[url.length - 2];
    }
    if (url[url.length - 1]) {
      this.oid = url[url.length - 1]
    }
    if (!this.calanderID || !this.appointmentTypeID || !this.oid) {
      this.router.navigateByUrl(`/location`)
    }

    this.items = [
      { label: 'Select clinic' },
      { label: 'Enter personal details' },
      { label: 'Make payment' },
      { label: 'Enter further details' }
    ];
  }

  ngOnInit(): void {
    this.getAppointmentTypesByID();
    let dateFormat = moment().format('YYYY-MM');
    let input = { 'appointmentID': this.appointmentTypeID, 'calandarID': this.calanderID, 'month': dateFormat }
    this.availabiltyDates(input)
  }

  getAppointmentTypesByID() {
    let input = { id: this.appointmentTypeID }
    this.customFormsService.getAppointmentTypesById(input).subscribe((data) => {
      this.appointmentTypes = data;
      this.appointmentName = data[0].name.trim()
      this.getForms()
    })
  }

  getForms() {
    this.appointmentTypes.forEach(element => {
      if (element.id == parseInt(this.appointmentTypeID)) {
        let input = { id: element.formIDs[0] }
        this.customFormsService.getAppointmentFormsById(input).subscribe((data) => {
          data.forEach(element1 => {
            if (element.formIDs[0] === element1.id) {
              this.selectedForm = element1;
              this.selectedForm.fields.forEach((element2, k) => {
                element2['value'] = '';
                if (element2.type === 'dropdown') {
                  let optionsArr = []
                  element2.options.forEach((element3, l) => {
                    let obj = {
                      'name': element3,
                      'value': element3,
                    }
                    optionsArr.push(obj)
                    if (l === element2.options.length - 1) {
                      element2['value'] = optionsArr[0];

                      element2.options = optionsArr
                    }
                  });
                }
                if (this.selectedForm.fields.length - 1 === k) {
                  this.showForm = true;
                }
              });
            }
          });
        })
      }
    });
  }

  newAppointment() {
    let statusArr = []
    if (this.selectedForm.fields.length > 0) {
      this.selectedForm.fields.forEach(async (element, i) => {
        if (element.required === true && element.value.length === 0) {
          if (element.type === 'dropdown') {
            element.value = element.options[0]
          } else {
            statusArr.push(true)
          }
        }

        if (i === this.selectedForm.fields.length - 1) {
          if (typeof this.selectedTime === 'object') {
            this.selectedTime = this.selectedTime.value
          } else {
            this.selectedTime = this.selectedTime
          }

          if (statusArr.includes(true)) {
            this.toaterMsg = [
              { severity: 'error', summary: 'Error', detail: 'Please fill all required fields.', life: 3000 }
            ];
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          } else {

            await this.selectedForm.fields.forEach(element1 => {
              if (element1.name === 'Date of Birth (DD/MM/YYYY)') {
                element1.value = moment(element1.value).format('DD-MM-YYYY')
              }
            });

            

            this.customFormsService.getByID(this.oid).subscribe((data) => {
              this.bookingForm['firstName'] = data['firstName'];
              this.bookingForm['lastName'] = data['lastName'];
              this.bookingForm['email'] = data['email'];
              this.bookingForm['phone'] = data['phone'];
              this.bookingForm['selectedTime'] = this.selectedTime;
              this.bookingForm['oid'] = this.oid;

              if (this.bookingForm['phone'].length === 0) {
                this.bookingForm['phone'] = 'NA'
              }
              let input =
              {
                "datetime": this.selectedTime,
                "appointmentTypeID": this.appointmentTypeID,
                'calandarID': this.calanderID,
                "firstName": this.bookingForm['firstName'],
                "lastName": this.bookingForm['lastName'],
                "email": this.bookingForm['email'],
                "phone": this.bookingForm['phone'],
                "fields": this.selectedForm.fields
              }
              this.customFormsService.postNewApoointment(input).subscribe((data) => {
                if (data['status_code'] && data['status_code'] === 400) {
                  this.toaterMsg = [
                    { severity: 'error', summary: 'Error', detail: data['message'], life: 3000 }
                  ];
                } else {
                  let dataToSend = this.bookingForm;
                  dataToSend['acuity_id'] = data['id']
                  this.customFormsService.updateTransactionForm(dataToSend).subscribe((data) => {
                    this.toaterMsg = [
                      { severity: 'success', summary: 'Success', detail: 'New appointment Booked', life: 3000 }
                    ];
                    // this.router.navigateByUrl('/confirmation/appointment')
                    this.router.navigateByUrl('/transaction-status?success=true')
                  })
                }

                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              })
            })
          }
        }
      });
    }
  }

  monthChanged(event) {
    let format = moment(`${event.year}-${event.month}`).format('YYYY-MM')
    let input = { 'appointmentID': this.appointmentTypeID, 'calandarID': this.calanderID, 'month': format }
    this.availabiltyDates(input);
  }

  availabiltyDates(input) {
    this.customFormsService.getAvailabilityDates(input).subscribe((data) => {
      let arr = []
      let arr1 = []
      if (data.length > 0) {
        data.forEach((element, i) => {
          arr.push(parseInt(element.date.split('-')[2]))
          arr1.push(parseInt(element.date.split('-')[1]))
          if (i === data.length - 1) {
            this.availableDates = arr;
            this.availabileDatesMonths = arr1;
          }
        });
      } else {
        this.availableDates = [0];
        this.availabileDatesMonths = [0];
      }
    })
  }

  daySelected(date) {
    if ((this.availableDates.includes(date.day))) {
      let dateFormat = `${date.year}-${date.month + 1}-${date.day}`;
      let input = { 'appointmentID': this.appointmentTypeID, 'calandarID': this.calanderID, 'date': dateFormat }
      this.customFormsService.getAvailabilityTimes(input).subscribe((data) => {
        this.timeoptions = []
        let arr = []
        data.forEach((times, i) => {
          if (times.slotsAvailable !== 0) {
            let obj = {}
            //daylight saving
            // if( moment(dateFormat).isAfter('2023-10-29')){
              obj={
               'name': moment.utc(times.time).add(1, 'hour').format('HH:mm'),
                'value': times.time 
              }
            // }
            // else{
            //   obj={
            //     'name': moment.utc(times.time).format('HH:mm'),
            //     'value': times.time
            //   }
            // }           
            arr.push(obj);
          }
          if (i === data.length - 1) {
            this.timeoptions = arr;
            this.appointmentTypes.forEach(element => {
              if (element.id == parseInt(this.appointmentTypeID)) {
                let input = { id: element.formIDs[0] }
                this.customFormsService.getAppointmentFormsById(input).subscribe((data) => {
                  data.forEach(element1 => {
                    if (element.formIDs[0] === element1.id) {
                      this.selectedForm = element1;
                      this.selectedForm.fields.forEach((element2, k) => {
                        element2['value'] = '';
                        if (element2.type === 'dropdown') {
                          let optionsArr = []
                          element2.options.forEach((element3, l) => {
                            let obj = {
                              'name': element3,
                              'value': element3,
                            }
                            optionsArr.push(obj)
                            if (l === element2.options.length - 1) {
                              element2.options = optionsArr
                            }
                          });
                        }
                        if (this.selectedForm.fields.length - 1 === k) {
                          if (this.timeoptions[0]) {
                            this.selectedTime = this.timeoptions[0].value
                            this.showForm = true;
                          }
                        }
                      });

                    }
                  });
                })
              }
            });
          }
        });
      })
    } else {
      this.messageService.add({ severity: 'error', detail: `No available slots`, life: 3000 });
    }
  }
}
