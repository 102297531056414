import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Message, SelectItem } from "primeng/api";
import { QuestionnaireService } from '../quest-personal-details/questionnaire.service';

@Component({
  selector: 'app-quest-medical-history',
  templateUrl: './quest-medical-history.component.html',
  styleUrls: ['./quest-medical-history.component.scss']
})
export class QuestMedicalHistoryComponent implements OnInit {

  selectedForm: any;
  showForm: boolean = true;
  disableSubmit: boolean = false;
  cars: SelectItem[];
  user_name: any = "";
  user_age: any = "";
  getUrl: any = "";
  toaterMsg: Message[];
  questionnaire: any = [];
  questionnairePackage: any = [];
  
  constructor(private router: Router, private questService : QuestionnaireService) {}

  ngOnInit(): void {
    this.getUrl = this.router.url.split('=')[1]
    this.getPackageDetails()
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  nextPage() {
    console.log(this.selectedForm)
    let statusArr = []
    this.selectedForm.forEach((element)=>{
      console.log(element.value, typeof element.value)
      let value
      if(typeof element.value === 'string'){
        value = element.value.trim();
        if(element.required && (value.length === 0 || element.value === null)){
          statusArr.push(true)
        }
      }
    })
    if (statusArr.includes(true)) {
      console.log("required")
      this.toaterMsg = [
        { severity: 'error', summary: 'Error', detail: 'Please fill all required fields.', life: 3000 }
      ];
      this.clearToast();
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      // this.selectedForm.forEach((element)=>{ 
      //   if(element.name.toLowerCase().includes('date')){
      //     element.value = moment(element.value).format("YYYY-MM-DD");
      //   }   
      // })
      this.updateAnswers();
    }
  }

  back() {
    this.router.navigate(['nutrition-package-questionnaire'], { queryParams: { type: this.getUrl } })
  }

  clearToast(){
    setTimeout(() => {
      this.toaterMsg = []
    }, 5000);
  }

  getPackageDetails(){
    let body = this.getUrl;
    this.questService.getNutritionByURL(body).subscribe(res =>{
      this.questionnairePackage = res;
      this.questionnaire = JSON.parse(res[0].questions_answered)
      // this.selectedForm = JSON.parse(res[0].questions_answered)
      this.selectedForm = JSON.parse(res[0].questions_answered)["medical_history"]
      JSON.parse(res[0].questions_answered)["personal_details"].forEach(element => {
        if(element.name === "Name"){
          this.user_name = element.value
        }
        if(element.name === "Age"){
          this.user_age = element.value
        }
      });

    })
  }

  updateAnswers(){
    console.log(this.selectedForm)
    this.questionnaire["medical_history"] = this.selectedForm;
    this.questionnairePackage[0].questions_answered = JSON.stringify(this.questionnaire) 
    let body = this.questionnairePackage[0];
    console.log(body)
    this.questService.updateNutritionPackage(body).subscribe(res =>{
      console.log(res)
      this.router.navigate(['questionnaire-wellbeing'], { queryParams: { type: res["url"] } })
    })
  }
  
}
