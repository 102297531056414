import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CustomFormsService } from '../custom-forms/custom-forms.service';
import { Message, MessageService } from 'primeng/api';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MenuItem} from 'primeng/api';
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  appointmentTypes: any;
  appointmentTypeID: any;
  calanderID: any;
  selectedTypeName: any;
  locationName: any;
  allCalanders: any;
  filtercalendar: any;
  filtercalendarExternal: any;
  items: MenuItem[];
  constructor(private router: Router, private customFormsService: CustomFormsService, private messageService: MessageService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    sessionStorage.clear();
    this.route.queryParams
      .subscribe(params => {
        this.appointmentTypeID = params.appointmentTypeID;
      });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.appointmentTypeID) {
      this.items = [
        { label: 'Select clinic' },
        { label: 'Enter personal details' },
        { label: 'Enter mandatory details' },
        { label: 'Make payment' }
      ];
      this.getAppointmentTypes()
    } else {
      // this.router.navigateByUrl(`/confirmation/expired`)
      this.router.navigateByUrl(`/transaction-status?canceled=true`)

    }
    localStorage.removeItem("selected location");
  }

  getAppointmentTypes() {
    this.customFormsService.getAppointmentTypes().subscribe((data) => {
      this.appointmentTypes = data;
      this.getCalanders();
    })
  }

  getCalanders() {
    this.customFormsService.getCalanders().subscribe((data) => {
      this.allCalanders = data;
      this.selectedType()

    })
  }

  selectedType() {
    this.filtercalendarExternal = []
    let item = this.appointmentTypes.find(x => x.id === parseInt(this.appointmentTypeID));
    // this.appointmentTypeID = event.value.id;
    let arr = [];
    this.allCalanders.forEach((element, i) => {
      item.calendarIDs.forEach((element1, j) => {
        if (element.id === element1) {
          arr.push(element)
        }
        if (i === this.allCalanders.length - 1 && j === item.calendarIDs.length - 1)
          this.locationName = arr;
      });
    });
    let sortedArr = [];
    let notOrderedArr = [];
    if(this.locationName && this.locationName.length > 0){
      this.locationName.forEach((element, i) => {
        if (element.name.includes('Wimpole')) {
          sortedArr[0] = element
        } 
        // else if (element.name.includes('Hyatt')) {
        //   sortedArr[1] = element
        // } 
        else if (element.name.includes('Watford - 94 The Parade')) {
          sortedArr[2] = element
        } else if (element.name.includes('Croydon')) {
          sortedArr[3] = element
        } else if (element.name.includes('Cambridge')) {
          sortedArr[4] = element
        } else if (element.name.includes('Milton Keynes')) {
          sortedArr[5] = element
        } else if (element.name.includes('Ealing')) {
          sortedArr[6] = element
        } else if (element.name.includes('Essex')) {
          sortedArr[7] = element
        } else if (element.name.includes('Muswell Hill')) {
          sortedArr[8] = element
        }else if (element.name.includes('Partner Portal (internal)')) {
          
        }else{
          notOrderedArr.push(element)
        }
        if(element.name.includes('Concepto Clinic')){
          element.type = 'internal'
        }else if(element.name.includes('Window to the Womb')){
          element.type = 'internal'
        }else{
          element.type = 'external';
          if (element.name.includes('Partner Portal (internal)')) {     
          }else{
            this.filtercalendarExternal.push(element)
          }
        }
        if (this.locationName.length - 1 === i) {
          this.filtercalendar = [ ...sortedArr, ...notOrderedArr];
        }
      });
    }else{
      this.filtercalendar = []
    }
  
  }

  selectedLocation(data) {
    localStorage.setItem("selected location", data.name)
    this.calanderID = data.id;
    this.router.navigateByUrl(`/personal-details?calanderID=${this.calanderID}&appointmentTypeID=${this.appointmentTypeID}`)
  }

  search(input) {
    if (input !== "") {
      input = input.toLowerCase();
      let array = this.locationName.filter(x => x.name.toLowerCase().includes(input));
      this.filtercalendar = array;
    }
    else {
      this.filtercalendar = this.locationName;
    }
  }

  findClinic(item){
    let location = item.name.trim().replaceAll(/,|- /g, '').replaceAll(' ', '-').replace(/\u00A0/g, '-')
    let clinic = '';
    if(location === 'Concepto-Clinic-Gatwick-Airport-London'){
      clinic = 'gatwick-airport'
    }
    if(location === 'Concepto-Clinic-Hyatt-Place-London-Heathrow-Airport'){
      clinic = 'heathrow-airport';
    }
    if(location === 'Concepto-Clinic-Wimpole-Street'){
      clinic = 'london';
    }
    if(location === 'Concepto-Clinic-ASDA-Watford' || 'Watford-94-The-Parade'){
      clinic = 'watford';
    }
    if(location === 'Concepto-Clinic-Ealing'){
      clinic = 'west-london';
    }
    if(location === 'Concepto-Clinic-Milton-Keynes'){
      clinic = 'milton-keynes';
    }
    if(location === 'Concepto-Clinic-Bristol'){
      clinic = 'bristol';
    }
    if(location === 'Concepto-Clinic-Oxford'){
      clinic = 'oxford';
    }
    if(location === 'Concepto-Clinic-Cambridge'){
      clinic = 'cambridge';
    }
    if(location === 'Concepto-Clinic-Brentwood-Essex'){
      clinic = 'essex';
    }
    if(location === 'Concepto-Clinic-North-London-Muswell-Hill'){
      clinic = 'north-london';
    }
    if(location === 'Concepto-Clinic-Croydon'){
      clinic = 'south-london'
    }
    if(location === 'Concepto-Clinic-Soho'){
      clinic = 'soho'
    }
    window.open('http://conceptodiagnostics.co.uk/clinics/'+clinic)

  }
}
