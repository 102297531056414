import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../app.constants';


@Injectable()
export class QuestionnaireService {
    male_adult = 'assets/json/male-adult.json';
    female_adult = 'assets/json/female-adult.json';
    male_child = 'assets/json/male-child.json';
    female_child = 'assets/json/female-child.json';

    constructor(private http: HttpClient) { }

    getQuestions(type) {
        if(type === "male-adult"){
            return this.http.get(this.male_adult);
        }
        if(type === "female-adult"){
            return this.http.get(this.female_adult);
        }
        if(type === "male-child"){
            return this.http.get(this.male_child);
        }
        if(type === "female-child"){
            return this.http.get(this.female_child);
        }
    }

    getNutritionByURL(url): Observable<any[]> {
        return this.http.get<any[]>(`${AppConstants.CART_ENDPOINT}${AppConstants.API.FORMS.NUTRITION_PACKAGE_BY_URL}?url=${url}`);
    }

    updateNutritionPackage(data): Observable<any[]> {
        return this.http.post<any[]>(`${AppConstants.CART_ENDPOINT}${AppConstants.API.FORMS.NUTRITION_PACKAGE_UPDATE}`, data);
    }
}