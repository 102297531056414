import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CustomFormsService } from './custom-forms.service';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sha256 } from 'js-sha256';
import { AppConstants } from '../app.constants';

@Component({
  selector: 'app-custom-forms',
  templateUrl: './custom-forms.component.html',
  styleUrls: ['./custom-forms.component.scss']
})
export class CustomFormsComponent implements OnInit {
  appointmentTypes: any;
  selectedForm: any;
  customForm: FormGroup;
  showForm = false;
  custom_fields: any;
  appointmentTypeID: any;
  calanderID: any;
  toaterMsg: Message[];
  appointmentName = ''
  bookingForm = {};
  items: MenuItem[];
  todaydateTime = new Date();
  appointmentPrice: any;
  disableSubmit = false;
  selectedDate: any;
  selectedTime: any;
  location: any;
  grandTotal: any = 0;

  uploadedFiles: any[] = [];
  base64ImageValue: any
  fileUpload: any;
  imageValidation: boolean = false;
  acceptedFiles = '.pdf, .jpg, .png,';
  couponCode: any;
  couponCodeList = [];
  // discount: {};
  disableCromosome = false;
  promocode = '';
  isNotValidPromoCode = false;
  isPromocodeApplied = false;
  discountPercentage = 0;
  discount = '0.00';
  couponUsed = 0 ;
  couponId: any;

  constructor(private router: Router, private customFormsService: CustomFormsService, private messageService: MessageService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    this.route.queryParams
      .subscribe(params => {
        this.calanderID = params.calanderID;
        this.appointmentTypeID = params.appointmentTypeID;
      });
    this.customFormsService.currentForm.subscribe(form => this.bookingForm = form)
    this.bookingForm = JSON.parse(sessionStorage.getItem('bookingForm'))
    if (!this.calanderID || !this.appointmentTypeID) {
      this.router.navigateByUrl(`/location`)
    } else if (Object.keys(this.bookingForm).length === 0 && this.calanderID && this.appointmentTypeID) {
      this.router.navigateByUrl(`/personal-details?calanderID=${this.calanderID}&appointmentTypeID=${this.appointmentTypeID}`)
    }
    this.items = [
      { label: 'Select clinic' },
      { label: 'Enter personal details' },
      { label: 'Enter mandatory details' },
      { label: 'Make payment' }
    ];
    this.couponCodeList = [
      {
        'code': 'CONCEPTOCD10',
        'percentage': 10,
        'percentValue': 0.10,
        'testName': ['NIPT Advance']
      },
      {
        'code': 'CONCEPTONIPT20',
        'percentage': 20,
        'percentValue': 0.20,
        'testName': ['NIPT Advance']
      }
    ]
  }

  ngOnInit(): void {
    var bookingForm = JSON.parse(sessionStorage.getItem('bookingForm'))
    if (bookingForm.selectedTime.value) {
      this.selectedDate = bookingForm.selectedTime.value.split('T')[0];
      this.selectedTime = bookingForm.selectedTime.name;
    } else {
      this.selectedDate = bookingForm.selectedTime.split('T')[0];
      this.selectedTime = bookingForm.selectedTime.split('T')[1].split('+')[0].slice(0, 5);
    }
    this.location = localStorage.getItem("selected location")
    this.getAppointmentTypesByID();
  }

  getAppointmentTypesByID() {
    let input = { id: this.appointmentTypeID }
    this.customFormsService.getAppointmentTypesById(input).subscribe((data) => {
      this.appointmentTypes = data;
      this.appointmentPrice = data[0]['price'];
      this.appointmentName = data[0].name.trim();
      this.grandTotal = data[0]['price'];
      this.getForms()
    })
  }


  getForms() {
    this.appointmentTypes.forEach(element => {
      if (element.id == parseInt(this.appointmentTypeID)) {
        let input = { id: element.formIDs[0] }
        this.customFormsService.getAppointmentFormsById(input).subscribe((data) => {
          data.forEach(element1 => {
            if (element.formIDs[0] === element1.id) {
              this.selectedForm = element1;
              this.selectedForm.fields.forEach((element2, k) => {
                element2['value'] = '';
                if (element2.type === 'dropdown') {
                  let optionsArr = []
                  element2.options.forEach((element3, l) => {
                    let obj = {
                      'name': element3,
                      'value': element3,
                    }
                    optionsArr.push(obj)
                    if (l === element2.options.length - 1) {
                      element2['value'] = optionsArr[0];
                      element2.options = optionsArr
                    }
                  });
                }
                if (this.selectedForm.fields.length - 1 === k) {
                  this.showForm = true;
                }
              });
            }
          });
          console.log("form fields", this.selectedForm)
        })
      }
    });
  }

  clearToast() {
    setTimeout(() => {
      this.toaterMsg = []
    }, 5000);
  }

  newAppointment() {
    // debugger
    this.disableSubmit = true;
    let statusArr = []
    if (this.selectedForm.fields.length > 0) {
      this.selectedForm.fields.forEach(async (element, i) => {
        let value
        if (typeof element.value === 'string') {
          value = element.value.trim();
          if (element.required && (value.length === 0 || element.value === null)) {
            statusArr.push(true)
          }
        }
        if (element.required && (element.type === "checkbox")) {
          if (element.value.length === 0) {
            statusArr.push(true)
          }
        }
        // if(element.)
        if (i === this.selectedForm.fields.length - 1) {
          if (statusArr.includes(true)) {
            this.disableSubmit = false;
            this.toaterMsg = [
              { severity: 'error', summary: 'Error', detail: 'Please fill all required fields.', life: 3000 }
            ];
            this.clearToast();
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
          else {
            if (this.bookingForm['phone'].length === 0) {
              this.bookingForm['phone'] = 'NA'
            }

            if (typeof this.bookingForm['selectedTime'] === 'object') {
              this.bookingForm['selectedTime'] = this.bookingForm['selectedTime'].value
            } else {
              this.bookingForm['selectedTime'] = this.bookingForm['selectedTime']
            }

            await this.selectedForm.fields.forEach(element1 => {
              if (element1.name === 'Date of Birth (DD/MM/YYYY)') {
                element1.value = moment(element1.value).format('DD-MM-YYYY')
              }
              if (element1.type === "dropdown") {
                element1.value = element1.value.value
              }
              if (element1.type === "checkbox") {
                element1.value = element1.value[0]
              }
            });

            let input =
            {
              "datetime": this.bookingForm['selectedTime'],
              "appointmentTypeID": this.appointmentTypeID,
              'calandarID': this.calanderID,
              "firstName": this.bookingForm['firstName'],
              "lastName": this.bookingForm['lastName'],
              "email": this.bookingForm['email'],
              "phone": this.bookingForm['phone'],
              "fields": this.selectedForm.fields
            }
            let price = ''
            let cupoun_code = ''
            if (this.discount && this.discount['status'] === 'approved') {
              price = this.discount['price'].toString();
              cupoun_code = this.discount['code']
            } else {
              price = this.appointmentPrice.toString()
            }
            let saveToDb = {
              firstName: this.bookingForm['firstName'],
              lastName: this.bookingForm['lastName'],
              phone: this.bookingForm['phone'],
              email: this.bookingForm['email'],
              selectedTime: this.bookingForm['selectedTime'],
              appointmentID: this.appointmentTypeID,
              calandarID: this.calanderID,
              appointment_total: price,
              acuity_id: '',
              type: 'appointment',
              appointmentDetails: JSON.stringify(input),
              temp_id: sessionStorage.getItem("tempID"),
              cupoun_code: this.promocode,
              discount: this.discount,
              couponUsed: this.couponUsed,
              couponId: this.couponId,
            }
            await this.customFormsService.addTransaction(saveToDb).subscribe((data1) => {
              let dataToSend = this.bookingForm;
              dataToSend['acuity_id'] = '';
              let customerid = data1['id']
              let storeID = AppConstants.storeId
              let sharedS = AppConstants.sharedS
              var today = new Date();
              let formatedDate = moment().format('YYYY:MM:DD-HH:mm:ss')
              let totalChanged = this.grandTotal.toString()
              let currencyCode = "gbp";
              let stringToBeHashed = `${storeID}${formatedDate}${totalChanged}${currencyCode}${sharedS}`;
              let stringToHex = this.toHex(stringToBeHashed)
              let hash = sha256.hex(stringToHex);
              let obj = {
                "txntype": "sale",
                "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
                "txndatetime": formatedDate,
                "hash_algorithm": "SHA256",
                "hash": hash,
                "storename": storeID,
                "checkoutoption": "combinedpage",
                "customerid": customerid,
                "mode": "payonly",
                "chargetotal": totalChanged,
                "currency": currencyCode,
                "authenticateTransaction": 'true',
                "name" : saveToDb.firstName + ' '+ saveToDb.lastName
              }
              this.customFormsService.makepayment(obj).subscribe((paymentResponse) => {
                this.disableSubmit = false;
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
                document.location.href = paymentResponse['data'];
              })
            })
          }
        }
      });
    }
  }

  toHex(str) {
    var result = '';
    for (var i = 0; i < str.length; i++) {
      result += str.charCodeAt(i).toString(16);
    }
    return result;
  }

  back() {
    this.router.navigateByUrl(`/personal-details?calanderID=${this.calanderID}&appointmentTypeID=${this.appointmentTypeID}`)
  }


  onfileUpload(event: any, fileUpload: any, field) {
    this.fileUpload = fileUpload
    // this.loading = true
    this.uploadedFiles = []
    let size = 0
    event.files?.forEach((file: any, i: any) => {
      size = size + file.size
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let full_name = file.name.split('.')
        let length = full_name.length
        let file_type = full_name[length - 1]
        let temp = full_name.splice(0, length - 1)
        let obj = {
          filename: temp.join(' '),
          type: file_type,
          file: reader.result
        }
        this.uploadedFiles.push(obj);
      };
      if (event.files.length - 1 === i) {
        let timeout = 3000
        if (size > 700000) {
          timeout = 5000
        }
        if (size > 1000000) {
          timeout = 7000
        }
        setTimeout(() => {
          let temp: any = []
          this.base64ImageValue = this.uploadedFiles
          this.base64ImageValue.forEach((el: any, i: any) => {
            el.file = el.file.split(',')[1]
            temp.push(el)
          })
          this.base64ImageValue = JSON.stringify(temp)
          let body = {
            "image": this.base64ImageValue
          }
          this.customFormsService.addImage(body).subscribe((res) => {
            field.value = JSON.parse(res['image']).image[0]
          })
          this.imageValidation = false
          // this.loading = false
        }, timeout);
      }
    });
  }

  // couponCodeApply() {
  //   this.discount = {}

  //   this.couponCodeList.forEach((element, i) => {
  //     if ((this.couponCode && this.couponCode.length > 0) && this.couponCode.toUpperCase() === element.code) {
  //       if(element.testName && element.testName.length > 0){
  //         if(element.testName.includes(this.appointmentName)){
  //           this.discount = element
  //           this.discount['status'] = 'approved';
  //           this.discount['price'] = (parseInt(this.appointmentPrice) - (parseInt(this.appointmentPrice) * element.percentValue)).toFixed(2);
  //         } else {
  //         this.discount = {}
  //       }
  //       }else{
  //         this.discount = element
  //         this.discount['status'] = 'approved';
  //         this.discount['price'] = (parseInt(this.appointmentPrice) - (parseInt(this.appointmentPrice) * element.percentValue)).toFixed(2);
  //       }
  //     }
  //   });
  // }


  // Promocode 
  applyPromocode(){
    this.customFormsService.validateCupon(this.promocode).subscribe((res=>{
      if(res.length > 0 && res[0]?.couponUsed < res[0]?.usageLimit){
        const testsArray = res[0]?.tests ? JSON.parse(res[0].tests) : null;
        // Check if the appointmentName matches any of the test names
        if (
          (testsArray &&
            testsArray.some(
              (test) => test.toLowerCase() == this.appointmentName.toLowerCase()
            )) ||
          !testsArray
        ) {
          this.isNotValidPromoCode = false;
          this.isPromocodeApplied = true;
          this.discountPercentage = parseFloat(`0.${res[0].discountpercentage}`);
          let finalTotal = this.appointmentPrice - (this.appointmentPrice * this.discountPercentage);
          this.discount = (this.appointmentPrice - finalTotal).toFixed(2)
          this.grandTotal = finalTotal.toFixed(2);
          this.couponUsed = res[0]?.couponUsed + 1
          this.couponId = res[0].couponId
        } else {
          // Invalid coupon
          this.manageInvalidPromocode()
        }
      }
      else{
        this.manageInvalidPromocode()
      }
    }))
  
  }

  manageInvalidPromocode(){
    this.discountPercentage = parseFloat(`0.00`);
    this.isNotValidPromoCode = true;
    this.isPromocodeApplied = false;
    this.grandTotal = this.appointmentPrice;
    this.discount = '0.00';
    this.couponUsed = 0;
  }

  checkforPregnancyType(field) {
    if (field && field.name === 'Pregnancy Type') {
      if (field.value && field.value.value === 'Twin pregnancy') {
        this.disableCromosome = true;
        if (this.appointmentTypes && this.appointmentTypes.length > 0) {
          if (this.appointmentTypes[0].id === 48202503) {
            this.disableSubmit = true;
          } else if (this.appointmentTypes[0].id === 48202489) {
            this.disableSubmit = true;
          } else {
            this.disableSubmit = false;
          }
        }
      } else {
        this.disableCromosome = false;
        this.disableSubmit = false;

      }
    }
  }
}
