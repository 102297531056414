import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '../app.constants';
import { CustomFormsService } from '../custom-forms/custom-forms.service';
import { QuestionnaireService } from '../quest-personal-details/questionnaire.service';

@Component({
  selector: 'app-quest-success',
  templateUrl: './quest-success.component.html',
  styleUrls: ['./quest-success.component.scss']
})
export class QuestSuccessComponent implements OnInit {

  user_name: any = "";
  user_age: any = ""
  getUrl: any = "";

  constructor(private router: Router, private questService : QuestionnaireService) { }

  ngOnInit(): void { 
    this.getUrl = this.router.url.split('=')[1]
    this.getPackageDetails()
  }

  homePage(){
    window.location.href = `${AppConstants.nutrition}`;
  }

  getPackageDetails(){
    let body = this.getUrl;
    this.questService.getNutritionByURL(body).subscribe(res =>{
      // this.questionnairePackage = res;
      // this.questionnaire = JSON.parse(res[0].questions_answered)
      // this.selectedForm = JSON.parse(res[0].questions_answered)["wellbeing"]
      JSON.parse(res[0].questions_answered)["personal_details"].forEach(element => {
        if(element.name === "Name"){
          this.user_name = element.value
        }
        if(element.name === "Age"){
          this.user_age = element.value
        }
      });

    })
  }
}
