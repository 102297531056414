import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompleteFormsComponent } from './complete-forms/complete-forms.component';
import { CustomFormsComponent } from './custom-forms/custom-forms.component';
import { LocationsComponent } from './locations/locations.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { QuestMedicalHistoryComponent } from './quest-medical-history/quest-medical-history.component';
import { QuestPersonalDetailsComponent } from './quest-personal-details/quest-personal-details.component';
import { QuestSuccessComponent } from './quest-success/quest-success.component';
import { QuestWellbeingComponent } from './quest-wellbeing/quest-wellbeing.component';
import { SuccessComponent } from './success/success.component';
import { TransactionStatusComponent } from './transaction-status/transaction-status.component';




const routes: Routes = [
  { path: '', redirectTo: '/location', pathMatch: 'full' },
  { path: 'location', component: LocationsComponent },
  { path: 'personal-details', component: PersonalDetailsComponent },
  { path: 'forms', component: CustomFormsComponent },
  { path: 'nutrition-package-questionnaire', component: QuestPersonalDetailsComponent},
  { path: 'questionnaire-medical-history', component: QuestMedicalHistoryComponent},
  { path: 'questionnaire-wellbeing', component: QuestWellbeingComponent},
  { path: 'questionnaire-success', component: QuestSuccessComponent},
  { path: 'personal-details?calanderID={calanderID}&appointmentTypeID={appointmentTypeID}', component: PersonalDetailsComponent },
  { path: 'forms?calanderID={calanderID}&appointmentTypeID={appointmentTypeID}', component: CustomFormsComponent },
  { path: 'location?appointmentTypeID={appointmentTypeID}', component: LocationsComponent },
  { path: 'confirmation/:status?:oid', component: SuccessComponent },
  { path: 'transaction-status', component: TransactionStatusComponent },
  // { path: 'success', component: SuccessComponent },
  { path: 'complete-forms/:calanderID/:appointmentTypeID/:oid', component: CompleteFormsComponent },


  { path: '**', redirectTo: '/location' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
