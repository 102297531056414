import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConstants } from './app.constants'
import { map, tap, catchError, finalize } from 'rxjs/operators';
import { BusyIndicatorService } from './busy-indicator.service';
import { Router } from '@angular/router';
const errMsg = '';
@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {
  token: any;
  accessToken: any;
  req: any;
  constructor(
    // private tokenService: TokenValidaterService,
    private busyIndicator: BusyIndicatorService,
    private router: Router,
    private messageService: MessageService,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showLoader();
    if (sessionStorage.getItem('token')) {
      this.token = sessionStorage.getItem('token');
    }
    if (request.url.includes('vetstoria')) {
      this.req = request.clone({
        url: `${AppConstants.API_ENDPOINT}${request.url}`,
        setHeaders: { Authorization: `Bearer ${this.token}` }
      });
    } else {
      this.req = request.clone({
        url: `${request.url}`,
        setHeaders: { Authorization: `Bearer ${this.token}` }
      });
    }
    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'multipart/form-data') });
    }
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    return next.handle(this.req)
      .pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {
            if (evt.body) {
              this.customException(evt);
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          // Client Side Error
          if (error.error.statusCode === 401) {
            this.messageService.add({ severity: 'error', detail: 'session expired', life: 600 });
            setTimeout(() => {
              this.router.navigateByUrl('/login');
            }, 1000)
          }
          if (error.error instanceof ErrorEvent) {
            this.customException(error);
          } else {  // Server Side Error
            this.customException(error);
          }
          return throwError(errMsg);
        }),
        finalize(() => this.hideLoader())
      );
  }

  private customException(resp): any {
    // switch (resp.status) {
    //   case 0:
    //     this.notificationService.notification('Application is busy, please try after few minutes!', 'danger');
    //     break;
    //   case 500:
    //     this.notificationService.notification(resp.error.message, 'danger');
    //     break;
    //   case 401:
    //     this.notificationService.notification(resp.error.message, 'danger');
    //     break;
    //   case (resp.status > 200 && resp.status <= 299):
    //     this.notificationService.notification(resp.body.message, 'success');
    //     break;
    //   case 201:
    //     this.notificationService.notification(resp.body.message, 'success');
    //     break;
    //   default:
    //     break;
    // }
  }

  private showLoader(): void {
    this.busyIndicator.setStatus(true);
  }
  private hideLoader(): void {
    this.busyIndicator.setStatus(false);
  }
}
