import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../app.constants';
import { CustomFormsService } from '../custom-forms/custom-forms.service';
import { QuestionnaireService } from '../quest-personal-details/questionnaire.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  appointmentTypeID: any;
  appointmentName = '';
  nutritionID: any;
  questionnairePackage: any = [];

  constructor(private router: Router, private customFormsService: CustomFormsService, private route: ActivatedRoute, private questService: QuestionnaireService) {
    route.queryParams
      .subscribe(params => {
        this.appointmentTypeID = params.appointmentTypeID;
        this.nutritionID = params.type
        if (this.appointmentTypeID !== undefined) {
          this.getAppointmentTypesByID();
        }
        if (this.nutritionID !== undefined) {
          this.getPackageDetails()
        }
      });
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (sessionStorage.getItem("appointmentName")) {
        this.appointmentName = sessionStorage.getItem("appointmentName")
      }
      if (this.appointmentTypeID !== undefined) {
        this.getAppointmentTypesByID();
      }
      if (this.nutritionID !== undefined) {
        this.getPackageDetails()
      }
    }, 2000);
  }

  getAppointmentTypesByID() {
    let input = { id: this.appointmentTypeID }
    this.customFormsService.getAppointmentTypesById(input).subscribe((data) => {
      this.appointmentName = data[0]?.name.trim()
      sessionStorage.setItem("appointmentName", this.appointmentName);
    })
  }

  getPackageDetails() {
    let body = this.nutritionID;
    this.questService.getNutritionByURL(body).subscribe(res => {
      this.questionnairePackage = res;
      this.appointmentName = res[0].package_name;
    })
  }
}
