import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusyIndicatorService } from './busy-indicator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private loaderSubscription: Subscription;
  isBusy: boolean;
  userDetails = {} as any

  constructor(
    private loaderService: BusyIndicatorService
  ) { }

  ngOnInit() {
    this.loaderSubscription = this.loaderService.getStatus().subscribe(status => setTimeout(() => {
      (this.isBusy = status);
    }));
  }

  ngOnDestroy(): void {
    this.loaderSubscription.unsubscribe();
  }
}
