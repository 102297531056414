import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Message } from 'primeng/api';
import { QuestionnaireService } from './questionnaire.service';

@Component({
  selector: 'app-quest-personal-details',
  templateUrl: './quest-personal-details.component.html',
  styleUrls: ['./quest-personal-details.component.scss']
})
export class QuestPersonalDetailsComponent implements OnInit {
  selectedForm: any;
  showForm: boolean = true;
  disableSubmit: boolean = false;
  getUrl: any = "";
  toaterMsg: Message[];
  questionnaire: any = [];
  questionnairePackage: any = [];

  constructor(private router: Router, private questService : QuestionnaireService, private locationStrategy: LocationStrategy) {}

  ngOnInit(): void { 
    sessionStorage.removeItem("user-personal-details");
    this.getUrl = this.router.url.split('=')[1]
    console.log(this.getUrl)
    this.getPackageDetails()
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }

  nextPage() {
    console.log(this.selectedForm)
    let statusArr = []
    this.selectedForm.forEach((element)=>{
      console.log(element.value, typeof element.value)
      let value
      if(typeof element.value === 'string'){
        value = element.value.trim();
        if(element.required && (value.length === 0 || element.value === null)){
          statusArr.push(true)
        }
      }
      
    })
    if (statusArr.includes(true)) {
      console.log("required")
      this.toaterMsg = [
        { severity: 'error', summary: 'Error', detail: 'Please fill all required fields.', life: 3000 }
      ];
      this.clearToast();
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      let temp = {
        name: "",
        age: ""
      } 
      this.selectedForm.forEach((element)=>{
        if(element.name === "Name"){
          temp.name = element.value
        }
        if(element.name === "Age"){
          temp.age = element.value
        }  
        // if(element.name.toLowerCase().includes('date')){
        //   element.value = moment(element.value).format("DD/MM/YYYY");
        // }    
      })
      this.updateAnswers()
    }
    
  }

  clearToast(){
    setTimeout(() => {
      this.toaterMsg = []
    }, 5000);
  }

  getPackageDetails(){
    let body = this.getUrl;
    this.questService.getNutritionByURL(body).subscribe(res =>{
      console.log(res)
      this.questionnairePackage = res;
      this.questionnaire = JSON.parse(res[0].questions_answered)
      this.selectedForm = JSON.parse(res[0].questions_answered)["personal_details"]
      console.log(this.selectedForm)

    })
  }

  updateAnswers(){
    console.log(this.selectedForm)
    this.questionnaire["personal_details"] = this.selectedForm;
    this.questionnairePackage[0].questions_answered = JSON.stringify(this.questionnaire) 
    let body = this.questionnairePackage[0];
    console.log(body)
    this.questService.updateNutritionPackage(body).subscribe(res =>{
      console.log(res)
      this.router.navigate(['questionnaire-medical-history'], { queryParams: { type: res["url"] } })
    })
  }

}
