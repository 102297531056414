import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { CustomFormsService } from '../custom-forms/custom-forms.service';
import { QuestionnaireService } from '../quest-personal-details/questionnaire.service';
import { AppConstants } from '../app.constants';
import * as moment from 'moment';

@Component({
  selector: 'app-transaction-status',
  templateUrl: './transaction-status.component.html',
  styleUrls: ['./transaction-status.component.scss']
})

export class TransactionStatusComponent implements OnInit {
    status = 'success';
    paymentId: any;
    calanderID: any;
    appointmentTypeID: any;
    acuityID: any;
    appointmentName: any;
    typeNutrition: boolean = false;
  oid: any;
    constructor(private router: Router, private customFormsService: CustomFormsService, private questService: QuestionnaireService) {
      let getUrl = this.router.url.split('?')[1].split('=')[0]
      console.log("getUrl---", this.status)
      if (getUrl === 'success') {
        this.status = 'success'
      } else if (getUrl === 'canceled') {
        this.status = 'failed'
      }else if (getUrl === 'appointment') {
        this.status = 'appointment'
      } else if(getUrl === 'cart'){
        this.status = 'cart'
      } 
      if (sessionStorage.getItem("appointmentName")) {
        this.appointmentName = sessionStorage.getItem("appointmentName")
      }
     }
  

  
     async ngOnInit(): Promise<void> {
        this.appointmentTypeID = sessionStorage.getItem("appointmentID");
        this.calanderID = sessionStorage.getItem("calandarID");
        this.acuityID = sessionStorage.getItem("acuityID");
        setTimeout(() => {
          window.scrollTo(0,0)
        }, 500);
        let getID = this.router.url.split('?')[1].split('&')[1].replace('id=', '')
        var decoded = CryptoJS.enc.Hex.parse(getID).toString(CryptoJS.enc.Base64);
        this.paymentId = CryptoJS.AES.decrypt(decoded, "Secret Passphrase").toString(CryptoJS.enc.Utf8);
        console.log("this.paymentId===",this.paymentId)
        if (this.paymentId) {
          this.updateOrder();
        }
      }

      async updateOrder() {
        let body = {
          id: this.paymentId,
          status:this.status
        }
        await this.customFormsService.verifyPayment(body).subscribe({
            next: (res: any) => {
                console.log("res--", res)
                if (res.status === 'success') {
                    this.status = 'success'
                    if (res.oid && res.oid.length > 0) {
                      if (res.oid.substr(res.oid.length - 1) === '=') {
                        res.oid = res.oid.slice(0, -1)
                      }
                      let formData = JSON.parse(sessionStorage.getItem('bookingForm'))
                      let bookingForm = JSON.parse(sessionStorage.getItem('bookingForm'))
                      formData['oid'] = res.oid;
                      this.oid = res.oid;
                      formData['appointmentID'] = this.appointmentTypeID;
                      formData['calandarID'] = this.calanderID;
                      formData['email'] = bookingForm['email'];
                      if (typeof formData['selectedTime'] === 'object') {
                        formData['selectedTime'] = formData['selectedTime'].value
                      } else {
                        formData['selectedTime'] = formData['selectedTime'];
                      }
                       this.customFormsService.updateTransaction(formData).subscribe((paymentResponse) => {
                        bookingForm['id'] = paymentResponse['id'];
                        bookingForm['oid'] = res.oid;
                        this.customFormsService.changFormSource(`${JSON.stringify(bookingForm)}`);
                      })
                    } else {
                      setTimeout(() => {
                        this.router.navigateByUrl(`/forms?calanderID=${this.calanderID}&appointmentTypeID=${this.appointmentTypeID}`)
                      }, 1000);
                    }
                  } else if(res.status == 'appointment') {
                    if (this.acuityID) {
                      let input = [this.acuityID];
                      let tests = [
                        {
                          name: "Advanced Nutrition Care during Pregnancy",
                          value: "female-adult"
                        },
                        {
                          name: "Optimal Child Development Test",
                          value: "male-child"
                        },
                        {
                          name: "Advanced Fertility Test",
                          value: "female-adult"
                        },
                        {
                          name: "Optimal Hormonal Balance",
                          value: "female-adult"
                        },
                        {
                          name: "Stress Test",
                          value: "male-adult"
                        },
                        {
                          name: "M.O.T Nutrition Check",
                          value: "female-child"
                        },
                        {
                          name: "Thyroid Health",
                          value: "female-child"
                        },
                      ]
                       this.customFormsService.getAcuityByID(input).subscribe((res) => {
                        let arr = []
                        tests.forEach((element, i) => {
                          if (element.name === res["data"][0]["type"]) {
                            arr.push(element)
                          }
                          if (tests.length - 1 == i) {
                            if (arr.length === 0) {
                              setTimeout(() => {
                                if (this.appointmentName && this.appointmentName === 'Early Gender Test') {
                                  sessionStorage.clear();
                                  window.location.href = `${AppConstants.blueorpink}`;
                                } else {
                                  sessionStorage.clear();
                                  window.location.href = `${AppConstants.diagnostics}`;
                                }
                              }, 1000);
                            } else {
                              this.typeNutrition = true
                              let dob;
                              let gender;
                              res["data"][0]["forms"][0]["values"].forEach(element => {
                                if (element.name === "Date of Birth (DD/MM/YYYY)") {
                                  dob = element.value;
                                }
                                if (element.name === "Gender") {
                                  gender = element.value;
                                }
                              })
                              let object = {
                                "id": 0,
                                "name": res["data"][0]["firstName"],
                                "email": res["data"][0]["email"],
                                "password": "",
                                "about_me": gender,
                                "phone": res["data"][0]["phone"],
                                "DOB": dob,
                                "address_line_1": "",
                                "city": " ",
                                "zipcode": " ",
                                "address_line_2": " ",
                                "country": " ",
                                "other_address": " ",
                                "created_at": new Date()
                              }
                              this.customFormsService.addUser(object).subscribe(response => {
                                sessionStorage.setItem("UserID", response['id'].toString());
                                let today = moment(new Date()).format('DD-MM-YYYY');
                                let age_year = parseInt(today.split('-')[2]) - parseInt(dob.split('-')[2])
                                let act_month = parseInt(today.split('-')[1]) - parseInt(dob.split('-')[1])
                                let age = (age_year * 12 + act_month) / 12;
              
                                let question_sets = {}
                                let type = ''
                                if (gender === "Male") {
                                  if (age < 18) {
                                    type = "male-child"
                                    this.questService.getQuestions(type).subscribe(resp => {
                                      question_sets = resp
                                      this.navigateToQuestions(response, question_sets, res, type)
              
                                    })
                                  }
                                  else {
                                    type = "male-adult"
                                    this.questService.getQuestions(type).subscribe(resp => {
                                      question_sets = resp
                                      this.navigateToQuestions(response, question_sets, res, type)
              
                                    })
                                  }
                                } else if (gender === "Female") {
                                  if (age < 18) {
                                    type = "female-child"
                                    this.questService.getQuestions(type).subscribe(resp => {
                                      question_sets = resp
                                      this.navigateToQuestions(response, question_sets, res, type)
              
                                    })
                                  } else {
                                    type = "female-adult"
                                    this.questService.getQuestions(type).subscribe(resp => {
                                      question_sets = resp
                                      this.navigateToQuestions(response, question_sets, res, type)
                                    })
                                  }
                                }
              
                              })
                            }
                          }
                        })
              
                      })
                    } else {
                      setTimeout(() => {
                        if (this.appointmentName && this.appointmentName === 'Early Gender Test') {
                          sessionStorage.clear();
                          window.location.href = `${AppConstants.blueorpink}`;
                        } else {
                          if (AppConstants.GA4 === true) {
                            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                            window.dataLayer.push({
                              event: "purchase",
                              ecommerce: {
                                transaction_id: this.oid,
                                affiliation: "Concepto Diagnostics",
                                value: 249,
                                tax: 0,
                                shipping: 0,
                                currency: "GBP",
                                coupon: "",
                                items: ['NIPT']
                              }
                            });
                          }
                          sessionStorage.clear();
                          window.location.href = `${AppConstants.diagnostics}`;
                        }
                      }, 3000);
                    }
                  } else if (res.status === 'expired') {
                    this.status = 'expired'
                  } else if(res.status != "cart")  {
                    this.status = 'failed'
                  }
                },
                error: (err) => {
                  this.status = 'failed'
                }
              })
            }
            randomString(length) {
              let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
              var result = '';
              for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
              return result;
            }
            async navigateToQuestions(response, question_sets, res, type) {
              let inputData = {
                id: 0,
                acuity_id: this.acuityID,
                user: response['id'],
                questions_answered: JSON.stringify(question_sets),
                package_name: res["data"][0]["type"],
                appointment_date: res["data"][0]["datetime"],
                clinic_name: res["data"][0]["location"],
                url: this.randomString(6),
                questionnaire_completed: 'false',
                name: res["data"][0]["firstName"],
                email: res["data"][0]["email"],
                status: 'New',
                nutritionist_comments: ''
              }
              let emailData = [
                {
                  name: res["data"][0]["firstName"],
                  email: res["data"][0]["email"],
                  packageName: res["data"][0]["type"],
                  url: `${AppConstants.API_ENDPOINT}nutrition-package-questionnaire?type=${inputData.url}`
                }
              ]
              await this.customFormsService.ConfirmationEmailNutrition(emailData).subscribe((result) => {
              })
              this.customFormsService.addNutritionPackage(inputData).subscribe((result) => {
                this.router.navigate([`/nutrition-package-questionnaire`], { queryParams: { type: result["url"] } })
              })
            }
            retry(input) {
              if (input === 'expired') {
                window.location.href = `${AppConstants.home}`;
              } else if (input === 'failed') {
                this.router.navigateByUrl(`/personal-details?calanderID=${this.calanderID}&appointmentTypeID=${this.appointmentTypeID}`)
              }
            }
            homepage() {
              document.location.href = `${AppConstants.diagnostics}`
            }
     }
          