import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CustomFormsService } from '../custom-forms/custom-forms.service';
import { Message, MessageService } from 'primeng/api';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../app.constants';
import { QuestionnaireService } from '../quest-personal-details/questionnaire.service';
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  appointmentTypeID: any;
  calanderID: any;
  status = 'success'
  oid: any;
  acuityID: any;
  typeNutrition: boolean = false;
  appointmentName: any
  constructor(private router: Router, private customFormsService: CustomFormsService, private messageService: MessageService, private formBuilder: FormBuilder, private route: ActivatedRoute, private questService: QuestionnaireService) {
    if (this.router.url.split("/")[this.router.url.split("/").length - 1].includes('?')) {
      this.status = this.router.url.split("/")[this.router.url.split("/").length - 1].split("?")[0]
      this.oid = this.router.url.split("/")[this.router.url.split("/").length - 1].split("?")[1].replace('pass=', '')
    } else {
      this.status = this.router.url.split("/")[this.router.url.split("/").length - 1]
    }

    if (sessionStorage.getItem("appointmentName")) {
      this.appointmentName = sessionStorage.getItem("appointmentName")
    }
  }

  async ngOnInit(): Promise<void> {
    this.appointmentTypeID = sessionStorage.getItem("appointmentID");
    this.calanderID = sessionStorage.getItem("calandarID");
    this.acuityID = sessionStorage.getItem("acuityID");
    if (this.status === 'success') {
      if (this.oid && this.oid.length > 0) {
        if (this.oid.substr(this.oid.length - 1) === '=') {
          this.oid = this.oid.slice(0, -1)
        }
        let formData = JSON.parse(sessionStorage.getItem('bookingForm'))
        let bookingForm = JSON.parse(sessionStorage.getItem('bookingForm'))
        formData['oid'] = this.oid;
        formData['appointmentID'] = this.appointmentTypeID;
        formData['calandarID'] = this.calanderID;
        formData['email'] = bookingForm['email'];
        if (typeof formData['selectedTime'] === 'object') {
          formData['selectedTime'] = formData['selectedTime'].value
        } else {
          formData['selectedTime'] = formData['selectedTime'];
        }
        // if (AppConstants.GA4 === true) {
        //   window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        //   window.dataLayer.push({
        //     event: "purchase",
        //     ecommerce: {
        //       transaction_id: this.oid,
        //       affiliation: "Concepto Diagnostics",
        //       value: 249,
        //       tax: 0,
        //       shipping: 0,
        //       currency: "GBP",
        //       coupon: "",
        //       items: ['NIPT']
        //     }
        //   });
        // }
        await this.customFormsService.updateTransaction(formData).subscribe((paymentResponse) => {
          bookingForm['id'] = paymentResponse['id'];
          bookingForm['oid'] = this.oid;
          this.customFormsService.changFormSource(`${JSON.stringify(bookingForm)}`);
          // this.router.navigateByUrl(`/forms?calanderID=${this.calanderID}&appointmentTypeID=${this.appointmentTypeID}`)
        })


      } else {
        setTimeout(() => {
          this.router.navigateByUrl(`/forms?calanderID=${this.calanderID}&appointmentTypeID=${this.appointmentTypeID}`)
        }, 1000);
      }
    } else if (this.status === 'appointment') {

      if (this.acuityID) {
        let input = [this.acuityID];
        let tests = [
          {
            name: "Advanced Nutrition Care during Pregnancy",
            value: "female-adult"
          },
          {
            name: "Optimal Child Development Test",
            value: "male-child"
          },
          {
            name: "Advanced Fertility Test",
            value: "female-adult"
          },
          {
            name: "Optimal Hormonal Balance",
            value: "female-adult"
          },
          {
            name: "Stress Test",
            value: "male-adult"
          },
          {
            name: "M.O.T Nutrition Check",
            value: "female-child"
          },
          {
            name: "Thyroid Health",
            value: "female-child"
          },
        ]
        await this.customFormsService.getAcuityByID(input).subscribe((res) => {
          let arr = []
          tests.forEach((element, i) => {
            if (element.name === res["data"][0]["type"]) {
              arr.push(element)
            }
            if (tests.length - 1 == i) {
              if (arr.length === 0) {
                setTimeout(() => {
                  if (this.appointmentName && this.appointmentName === 'Early Gender Test') {
                    sessionStorage.clear();
                    window.location.href = `${AppConstants.blueorpink}`;
                  } else {
                    sessionStorage.clear();
                    window.location.href = `${AppConstants.diagnostics}`;
                  }
                }, 1000);
              } else {
                this.typeNutrition = true
                let dob;
                let gender;
                res["data"][0]["forms"][0]["values"].forEach(element => {
                  if (element.name === "Date of Birth (DD/MM/YYYY)") {
                    dob = element.value;
                  }
                  if (element.name === "Gender") {
                    gender = element.value;
                  }
                })
                let object = {
                  "id": 0,
                  "name": res["data"][0]["firstName"],
                  "email": res["data"][0]["email"],
                  "password": "",
                  "about_me": gender,
                  "phone": res["data"][0]["phone"],
                  "DOB": dob,
                  "address_line_1": "",
                  "city": " ",
                  "zipcode": " ",
                  "address_line_2": " ",
                  "country": " ",
                  "other_address": " ",
                  "created_at": new Date()
                }
                this.customFormsService.addUser(object).subscribe(response => {
                  sessionStorage.setItem("UserID", response['id'].toString());
                  let today = moment(new Date()).format('DD-MM-YYYY');
                  let age_year = parseInt(today.split('-')[2]) - parseInt(dob.split('-')[2])
                  let act_month = parseInt(today.split('-')[1]) - parseInt(dob.split('-')[1])
                  let age = (age_year * 12 + act_month) / 12;

                  let question_sets = {}
                  let type = ''
                  if (gender === "Male") {
                    if (age < 18) {
                      type = "male-child"
                      this.questService.getQuestions(type).subscribe(resp => {
                        question_sets = resp
                        this.navigateToQuestions(response, question_sets, res, type)

                      })
                    }
                    else {
                      type = "male-adult"
                      this.questService.getQuestions(type).subscribe(resp => {
                        question_sets = resp
                        this.navigateToQuestions(response, question_sets, res, type)

                      })
                    }
                  } else if (gender === "Female") {
                    if (age < 18) {
                      type = "female-child"
                      this.questService.getQuestions(type).subscribe(resp => {
                        question_sets = resp
                        this.navigateToQuestions(response, question_sets, res, type)

                      })
                    } else {
                      type = "female-adult"
                      this.questService.getQuestions(type).subscribe(resp => {
                        question_sets = resp
                        this.navigateToQuestions(response, question_sets, res, type)
                      })
                    }
                  }

                })
              }
            }
          })

        })
      } else {
        setTimeout(() => {
          if (this.appointmentName && this.appointmentName === 'Early Gender Test') {
            sessionStorage.clear();
            window.location.href = `${AppConstants.blueorpink}`;
          } else {
            // if (AppConstants.GA4 === true) {
            //   window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            //   window.dataLayer.push({
            //     event: "purchase",
            //     ecommerce: {
            //       transaction_id: this.oid,
            //       affiliation: "Concepto Diagnostics",
            //       value: 249,
            //       tax: 0,
            //       shipping: 0,
            //       currency: "GBP",
            //       coupon: "",
            //       items: ['NIPT']
            //     }
            //   });

            //   (<any>window).fbq("track", "Purchase", {
            //     value: 249,
            //     currency: "GBP",
            //   });
            // }
            sessionStorage.clear();
            window.location.href = `${AppConstants.diagnostics}`;
          }
        }, 3000);
      }

    }
  }

  randomString(length) {
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
  }

  async navigateToQuestions(response, question_sets, res, type) {

    let inputData = {
      id: 0,
      acuity_id: this.acuityID,
      user: response['id'],
      questions_answered: JSON.stringify(question_sets),
      package_name: res["data"][0]["type"],
      appointment_date: res["data"][0]["datetime"],
      clinic_name: res["data"][0]["location"],
      url: this.randomString(6),
      questionnaire_completed: 'false',
      name: res["data"][0]["firstName"],
      email: res["data"][0]["email"],
      status: 'New',
      nutritionist_comments: ''
    }
    let emailData = [
      {
        name: res["data"][0]["firstName"],
        email: res["data"][0]["email"],
        packageName: res["data"][0]["type"],
        url: `${AppConstants.API_ENDPOINT}nutrition-package-questionnaire?type=${inputData.url}`
      }
    ]
    await this.customFormsService.ConfirmationEmailNutrition(emailData).subscribe((result) => {

    })
    this.customFormsService.addNutritionPackage(inputData).subscribe((result) => {
      this.router.navigate([`/nutrition-package-questionnaire`], { queryParams: { type: result["url"] } })
    })
  }

  retry(input) {
    if (input === 'expired') {
      window.location.href = `${AppConstants.home}`;
    } else if (input === 'failed') {
      this.router.navigateByUrl(`/personal-details?calanderID=${this.calanderID}&appointmentTypeID=${this.appointmentTypeID}`)
    }
  }

  homepage() {
    document.location.href = `${AppConstants.diagnostics}`
  }

}
