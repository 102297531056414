import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CustomFormsService } from '../custom-forms/custom-forms.service';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {
  availableDates: any;
  availabileDatesMonths: any;
  selectedDate: Date;
  selectedTime: any;
  timeoptions: any;
  appointmentTypes: any;
  selectedForm: any;
  bookingForm: FormGroup;
  showForm = false;
  appointmentTypeID: any;
  calanderID: any;
  toaterMsg: Message[];
  appointmentName = ''
  paymentlibaray: any;
  strikeCheckout: any = null;
  appointmentPrice = '0'
  dayLightSaving = true;
  items: MenuItem[];
  location: any;
  constructor(private router: Router, private customFormsService: CustomFormsService, private messageService: MessageService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    this.bookingForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern("^[a-zA-Z.]+([a-zA-Z. ]+)*$")]],
      lastName: ['', [Validators.required, Validators.pattern("^[a-zA-Z.]+([a-zA-Z. ]+)*$")]],
      phone: ['', [Validators.required, Validators.pattern("^[0-9]{09,13}$")]],
      email: ['', [Validators.required, Validators.email]]
    })
    this.route.queryParams
      .subscribe(params => {
        this.calanderID = params.calanderID;
        this.appointmentTypeID = params.appointmentTypeID;
      });
    this.items = [
      { label: 'Select clinic' },
      { label: 'Enter personal details' },
      { label: 'Enter mandatory details' },
      { label: 'Make payment' }
    ];
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getAppointmentTypesByID();
    let dateFormat = moment().format('YYYY-MM');
    let input = { 'appointmentID': this.appointmentTypeID, 'calandarID': this.calanderID, 'month': dateFormat }
    this.availabiltyDates(input)
    this.location = localStorage.getItem("selected location")
  }

  // convenience getters for easy access to form fields
  get f() { return this.bookingForm.controls; }

  getAppointmentTypesByID() {
    let input = { id: this.appointmentTypeID }
    this.customFormsService.getAppointmentTypesById(input).subscribe((data) => {
      this.appointmentTypes = data;
      this.appointmentPrice = data[0]['price'];
      this.appointmentName = data[0].name.trim()
    })
  }

  availabiltyDates(input) {
    this.customFormsService.getAvailabilityDates(input).subscribe((data) => {
      let arr = []
      let arr1 = []
      if (data.length > 0) {
        data.forEach((element, i) => {
          arr.push(parseInt(element.date.split('-')[2]))
          arr1.push(parseInt(element.date.split('-')[1]))
          if (i === data.length - 1) {
            this.availableDates = arr;
            this.availabileDatesMonths = arr1;
          }
        });
      } else {
        this.availableDates = [0];
        this.availabileDatesMonths = [0];
      }
    })
  }

  daySelected(date) {
    if ((this.availableDates.includes(date.day))) {
      let dateFormat = `${date.year}-${date.month + 1}-${date.day}`;
      let input = { 'appointmentID': this.appointmentTypeID, 'calandarID': this.calanderID, 'date': dateFormat }
      this.customFormsService.getAvailabilityTimes(input).subscribe((data) => {
        this.timeoptions = []
        let arr = []
        data.forEach((times, i) => {
          if (times.slotsAvailable !== 0) {
            let obj = {}
            // daylight saving 
            // if (moment(dateFormat).isAfter('2023-10-29')) {
              obj = {
                'name': moment(times.time).format('HH:mm'),
                'value': times.time
              }
            // } 
            // else {
            //   obj = {
            //     'name': moment(times.time).add(1, 'hour').format('HH:mm'),
            //     'value': moment(times.time).add(1, 'hour')
            //   }
            // }
            arr.push(obj);
          }
          if (i === data.length - 1) {
            this.timeoptions = arr;
            this.appointmentTypes.forEach(element => {
              if (element.id == parseInt(this.appointmentTypeID)) {
                let input = { id: element.formIDs[0] }
                this.customFormsService.getAppointmentFormsById(input).subscribe((data) => {
                  data.forEach(element1 => {
                    if (element.formIDs[0] === element1.id) {
                      this.selectedForm = element1;
                      this.selectedForm.fields.forEach((element2, k) => {
                        element2['value'] = '';
                        if (element2.type === 'dropdown') {
                          let optionsArr = []
                          element2.options.forEach((element3, l) => {
                            let obj = {
                              'name': element3,
                              'value': element3,
                            }
                            optionsArr.push(obj)
                            if (l === element2.options.length - 1) {
                              element2.options = optionsArr
                            }
                          });
                        }
                        if (this.selectedForm.fields.length - 1 === k) {
                          if (this.timeoptions[0]) {
                            this.selectedTime = this.timeoptions[0].value
                            this.showForm = true;
                          }
                        }
                      });
                    }
                  });
                })
              }
            });
          }
        });
      })
    } else {
      this.toaterMsg = [{ severity: 'error', detail: `No available slots`, life: 3000 }];
      this.clearToast();
    }
  }

  get testArray(): FormArray {
    return this.bookingForm.get('fields') as FormArray;
  }

  monthChanged(event) {
    if (event.month.length === 1) {
      event.month = `0${event.month}`
    }

    let format = `${event.year}-${event.month}`;
    let input = { 'appointmentID': this.appointmentTypeID, 'calandarID': this.calanderID, 'month': format }
    this.availabiltyDates(input);
  }

  onshowCal(input) {
    // console.log(input)
  }

  bin2hex(bin) {
    var i = 0, l = bin.length, chr, hex = ''
    for (i; i < l; ++i) {
      chr = bin.charCodeAt(i).toString(16)
      hex += chr.length < 2 ? '0' + chr : chr
    }
    return hex

  }

  async newAppointment() {
    if (this.bookingForm.status === 'VALID' && this.selectedTime) {
      sessionStorage.setItem("appointmentID", this.appointmentTypeID);
      sessionStorage.setItem("calandarID", this.calanderID);
      let formData = this.bookingForm.value;
      formData['selectedTime'] = this.selectedTime;
      this.customFormsService.changFormSource(`${JSON.stringify(formData)}`);
      let startTime;
      let endTime;
      if (this.selectedTime.value) {
        startTime = moment(this.selectedTime.value).utc().format('DD-MM-YYYY HH:mm:ss')
        endTime = moment(this.selectedTime.value).utc().add(this.appointmentTypes[0].duration, 'minutes').format('DD-MM-YYYY HH:mm:ss')
      } else {
        startTime = moment(this.selectedTime).utc().format('DD-MM-YYYY HH:mm:ss')
        endTime = moment(this.selectedTime).utc().add(this.appointmentTypes[0].duration, 'minutes').format('DD-MM-YYYY HH:mm:ss')
      }
      let input = {
        calandarID: this.calanderID,
        start: startTime,
        end: endTime
      }
      this.customFormsService.blockDates(input).subscribe((data) => {
        let blockDetails = {
          block_id: data['id'],
          created_date: moment().utc().format('YYYY-MM-DD HH:mm:ss')
        }
        this.customFormsService.addNewBlock(blockDetails).subscribe((block) => {
          sessionStorage.setItem("tempID", data['id']);
          this.router.navigateByUrl(`/forms?calanderID=${this.calanderID}&appointmentTypeID=${this.appointmentTypeID}`)
          // Uncomment the Below
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        })


      })
    } else {
      this.toaterMsg = []
      if (this.bookingForm.controls['firstName'].status === 'INVALID') {
        this.toaterMsg.push({ severity: 'error', summary: 'Error', detail: 'Please fill First name.', life: 3000 })
      }
      if (this.bookingForm.controls['lastName'].status === 'INVALID') {
        this.toaterMsg.push({ severity: 'error', summary: 'Error', detail: 'Please fill Last name.', life: 3000 })
      }
      if (this.bookingForm.controls['email'].status === 'INVALID') {
        this.toaterMsg.push({ severity: 'error', summary: 'Error', detail: 'Please fill valid Email address.', life: 3000 })
      }
      if (this.bookingForm.controls['phone'].status === 'INVALID') {
        this.toaterMsg.push({ severity: 'error', summary: 'Error', detail: 'Please fill valid Phone number.', life: 3000 })
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.clearToast();
    }
  }

  clearToast() {
    setTimeout(() => {
      this.toaterMsg = []
    }, 5000);
  }

  toHex(str) {
    var result = '';
    for (var i = 0; i < str.length; i++) {
      result += str.charCodeAt(i).toString(16);
    }
    return result;
  }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  back() {
    this.router.navigateByUrl(`/location?appointmentTypeID=${this.appointmentTypeID}`)
  }
}
