import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ToolbarModule } from 'primeng/toolbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccordionModule } from 'primeng/accordion';
import { ChartModule } from 'primeng/chart';
import { CardModule } from 'primeng/card';
import { TreeTableModule } from 'primeng/treetable';
import { PaginatorModule } from 'primeng/paginator';
import { StepsModule } from 'primeng/steps';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CustomFormsService } from './custom-forms/custom-forms.service';
import { CustomFormsComponent } from './custom-forms/custom-forms.component';
import { LoaderComponent } from './loader/loader.component';
import { ApiInterceptorService } from './api-interceptor.service';
import { LocationsComponent } from './locations/locations.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { SuccessComponent } from './success/success.component';
import { CompleteFormsComponent } from './complete-forms/complete-forms.component';
import { CompleteFormsService } from './complete-forms/complete-forms.service';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { QuestPersonalDetailsComponent } from './quest-personal-details/quest-personal-details.component';
import { QuestMedicalHistoryComponent } from './quest-medical-history/quest-medical-history.component';
import { QuestWellbeingComponent } from './quest-wellbeing/quest-wellbeing.component';
import { QuestionnaireService } from './quest-personal-details/questionnaire.service';
import { QuestSuccessComponent } from './quest-success/quest-success.component';
import { TransactionStatusComponent } from './transaction-status/transaction-status.component';

declare global {
  interface Window { dataLayer: any[]; }
}
@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    CustomFormsComponent,
    LocationsComponent,
    PersonalDetailsComponent,
    SuccessComponent,
    CompleteFormsComponent,
    FooterComponent,
    HeaderComponent,
    QuestPersonalDetailsComponent,
    QuestMedicalHistoryComponent,
    QuestWellbeingComponent,
    QuestSuccessComponent,
    TransactionStatusComponent
  ],
  imports: [
    CardModule,
    ChartModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    AppRoutingModule,
    TabViewModule,
    CheckboxModule,
    PdfViewerModule,
    AccordionModule,
    PaginatorModule,
    TreeTableModule,
    OverlayPanelModule,
    StepsModule

  ],
  providers: [MessageService, ConfirmationService, CustomFormsService, CompleteFormsService, QuestionnaireService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
